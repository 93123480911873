// 时间轴容器
.bsa-timeline1-wrapper {
  position: relative;

  // 时间轴中间的线条
  &::after {
    content: "";
    position: absolute;
    width: 3px;
    background-color: rgba(var(--bs-tertiary-color-rgb), .2);
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;

    // 小设备线条移动到左边
    @include media-breakpoint-down(md) {
      left: 29px;
    }
  }

  /* 内容周围的容器 */
  .bsa-timeline1 {
    position: relative;
    background-color: inherit;
    width: 50%;

    @include media-breakpoint-down(md) {
      width: 100%;
      padding-left: 70px;
      padding-right: 0;


      &::before {
        left: 61px;
        border-style: solid;
        border-width: 10px 10px 10px 0;
        border-color: transparent var(--bs-tertiary-color) transparent transparent;

      }

    }


    &::after {
      content: "";
      position: absolute;
      width: 20px;
      height: 20px;
      right: -8px;
      background-color: var(--bs-dark-bg-subtle); //颜色变浅
      border: 5px solid var(--bs-dark-border-subtle);
      top: 15px;
      border-radius: 50%;
      z-index: 1;
    }
  }

  /* 将容器放在左侧 */
  .bsa-timeline1-left {
    padding: 0 40px 20px 0;
    left: 0;


    // 箭头向右边的小三角
    &::before {
      content: " ";
      position: absolute;
      top: 17px;
      z-index: 1;
      right: 30px;
      border-style: solid;
      border-width: 10px 0 10px 10px;
      border-color: transparent transparent transparent rgba(var(--bs-tertiary-color-rgb), .2);
      @include media-breakpoint-down(md) {
        right: auto;
      }

    }

    @include media-breakpoint-down(md) {
      &::after {
        left: 18px;
      }
    }
  }

  /* 将容器放置在右侧 */
  .bsa-timeline1-right {
    padding: 0 0 20px 40px;
    left: 50%;

    @include media-breakpoint-down(md) {
      left: 0;
    }


    &::before {
      content: " ";
      position: absolute;
      top: 17px;
      z-index: 1;
      left: 31px;

      border-style: solid;
      border-width: 10px 10px 10px 0;
      border-color: transparent var(--bs-dark-bg-subtle) transparent transparent;

    }

    &::after {
      left: -12px;

      @include media-breakpoint-down(md) {
        left: 18px;
      }

    }
  }


}

// 时间轴2样式

ul {
  &.bsa-timeline2 {
    list-style-type: none;
    position: relative;

    &::before {
      content: " ";
      background: #d4d9df;
      display: inline-block;
      position: absolute;
      left: 29px;
      width: 2px;
      height: 100%;
      z-index: 400;
    }

    > li {
      margin: 20px 0;
      padding-left: 20px;

      &::before {
        content: " ";
        background: #fff;
        display: inline-block;
        position: absolute;
        border-radius: 50%;
        border: 3px solid #22c0e8;
        left: 20px;
        width: 20px;
        height: 20px;
        z-index: 400;
      }

    }

  }

}
