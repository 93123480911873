.bsa-back-to-top {
  display: none;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 26px;
  position: fixed;
  border-radius: 10px;
  bottom: 20px;
  right: 12px;
  color: var(--bs-light-text-emphasis);
  background-color: var(--bs-light-bg-subtle);
  z-index: 1028;

  @include media-breakpoint-down(lg) {
    z-index: 1001;
  }


  &:hover {
    color: var(--bs-dark-text-emphasis);
    background-color: var(--bs-dark-bg-subtle);
    transition: all .5s;
  }

}
