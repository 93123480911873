// 内容区域
.bsa-content {
  padding-left: var(--bsa-sidebar-width);
  padding-top: var(--bsa-header-height);
  padding-bottom: var(--bsa-footer-height);

  @include media-breakpoint-down(lg) {
    padding-left: 0;
  }

  &.bsa-content-fixed{
    position: fixed;
    inset: var(--bsa-header-height) 0 var(--bsa-footer-height) var(--bsa-sidebar-width);
    overflow: hidden;
    padding-left: unset !important;
    padding-top: unset !important;
    padding-bottom: unset !important;
    &.fullscreen {
      inset: 0;
      z-index: 9999;
    }
    @include media-breakpoint-down(lg) {
      left: 0;
    }
  }
}

