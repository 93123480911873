.bsa-layout2 {

  // 左侧的折叠类
  &.left-collapsed {

    // 左侧的给伸缩进去
    .bsa-layout2-left {
      transform: translateX(-101%);

      // 箭头也改成展开
      .bsa-chevron-toggle::after {
        content: "\F285";
      }
    }

    // 中间内容部分也取消到margin值
    .bsa-layout2-center {
      margin-left: 0;
    }

  }


  // 右侧的折叠类
  &.right-collapsed {

    // 右侧的给伸缩进去
    .bsa-layout2-right {
      transform: translateX(101%);

      // 箭头也改成展开
      .bsa-chevron-toggle::after {
        content: "\F284";
      }
    }

    // 中间内容部分也取消到margin值
    .bsa-layout2-center {
      margin-right: 0;
    }

  }


  // 左侧部分
  .bsa-layout2-left {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 240px;
    z-index: 1020;
    @include media-breakpoint-down(lg) {
      transition: .3s transform;
      will-change: transform;
      transform: translateX(-101%);

      &.left-open {
        transform: translateX(0) !important;

        .bsa-chevron-toggle::after {
          font-family: bootstrap-icons, serif;
          content: "\f284";
        }

      }

    }


    // 左侧箭头部分
    .bsa-chevron-toggle {
      position: absolute;
      width: 16px;
      height: 60px;
      right: -16px;
      top: 40%;
      display: flex;
      align-items: center;
      cursor: pointer;
      color: var(--bs-dark-text-emphasis);
      background-color: var(--bs-dark-bg-subtle);

      &::after {
        font-family: bootstrap-icons, serif;
        content: "\f284";

        @include media-breakpoint-down(lg) {
          content: "\F285";
        }

      }

      &:hover {
        color: var(--bs-light-text-emphasis);
        background-color: var(--bs-light-bg-subtle);
      }

    }

  }

  // 中间部分
  .bsa-layout2-center {
    margin-left: 244px;
    margin-right: 244px;

    @include media-breakpoint-down(lg) {
      margin-left: 0;
      margin-right: 0;
    }

  }

  // 右侧部分
  .bsa-layout2-right {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 240px;
    z-index: 1022;

    @include media-breakpoint-down(lg) {
      transition: .3s transform;
      will-change: transform;
      transform: translateX(101%);

      &.right-open {
        transform: translateX(0) !important;

        .bsa-chevron-toggle::after {
          font-family: bootstrap-icons, serif;
          content: "\f285";
        }

      }

    }

    // 右侧箭头部分
    .bsa-chevron-toggle {
      position: absolute;
      width: 16px;
      height: 60px;
      left: -16px;
      top: 40%;
      display: flex;
      align-items: center;
      cursor: pointer;
      color: var(--bs-dark-text-emphasis);
      background-color: var(--bs-dark-bg-subtle);

      &::after {
        font-family: bootstrap-icons, serif;
        content: "\f285";

        @include media-breakpoint-down(lg) {
          content: "\F284";
        }

      }

      &:hover {
        color: var(--bs-light-text-emphasis);
        background-color: var(--bs-light-bg-subtle);
      }

    }

  }


}

